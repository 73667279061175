export var fieldsDefinition = {
    fn_idCard: {
        label: '',
        type: 'boolean'
    },
    idCard: {
        label: '',
        type: 'text',
        options: [
            { value: 'Personalausweis', label: 'Personalausweis' },
            { value: 'Reisepass', label: 'Reisepass' }
        ]
    },
    nationality: {
        type: 'text',
        label: 'Nationalität *'
    },
    persoID: {
        type: 'text',
        label: 'Personalausweisnummer *',
        validation: {
            regex: ['^[0-9A-Z]{8,10}$', 'Da stimmt leider etwas noch nicht.']
        }
    },
    persoValidTo: {
        type: 'text',
        label: 'gültig Bis *'
    },
    passportID: {
        type: 'text',
        label: 'Ausweisnummer *',
        validation: {
            regex: ['^[0-9A-Za-z]{0,20}$', 'Da stimmt leider etwas noch nicht.']
        }
    },
    passportValidTo: {
        type: 'text',
        label: 'gueltigBis *'
    },
    sicherheitskennzahl: {
        label: 'Sicherheitskennzahl',
        type: 'text',
        validation: {
            required: ['Bitte geben Sie eine vierstellige Zahl ein.', 'isShowPassword'],
            regex: ['^\\d{4}$', 'Bitte geben Sie eine vierstellige Zahl ein.']
        }
    },
    showFax: {
        label: '',
        type: 'boolean'
    },
    showMobileNumber: {
        label: '',
        type: 'boolean'
    },
    showPhoneNumber: {
        label: '',
        type: 'boolean'
    },
    privatCustomer: {
        label: '',
        type: 'boolean'
    },
    showPassword: {
        label: '',
        type: 'boolean'
    },
    firmaRequired: {
        label: '',
        type: 'boolean'
    },
    fn_calendarWidget: {
        label: '',
        type: 'boolean'
    },
    mailRepetition: {
        label: '',
        type: 'boolean'
    },
    offerMode: {
        label: '',
        type: 'boolean'
    },
    birthdayLabel: {
        type: 'text',
        label: ''
    },
    salutation: {
        type: 'text',
        label: 'Anrede',
        options: [
            { label: 'Herr', value: 'Herr' },
            { label: 'Frau', value: 'Frau' },
            { label: 'Divers', value: 'Divers' },
            { label: 'Firma', value: 'Firma' },
            { label: 'Eheleute', value: 'Eheleute' },
            { label: 'Familie', value: 'Familie' }
        ],
        validation: {
            required: true
        }
    },
    title: {
        type: 'text',
        label: 'Titel',
        options: [
            { label: 'Dr.', value: 'Dr.' },
            { label: 'Prof.', value: 'Prof.' },
            { label: 'Prof. Dr.', value: 'Prof. Dr.' }
        ]
    },
    firstName: {
        type: 'text',
        label: 'Vorname',
        validation: {
            required: 'Bitte geben Sie einen Vorname ein.'
        }
    },
    lastName: {
        type: 'text',
        label: 'Nachname',
        validation: {
            required: 'Bitte geben Sie einen Nachname ein.'
        }
    },
    additionalPerson: {
        type: 'text',
        label: 'zusätzliche Person'
    },
    companyName: {
        label: 'Firma',
        type: 'text',
        validation: {
            required: ['dieses Feld kann nicht leer sein', 'isFirmaRequirede']
        }
    },
    email: {
        type: 'text',
        label: 'E-Mail',
        validation: {
            required: [null, 'dieses Feld kann nicht leer sein'],
            regex: ['^([A-Za-z0-9_.-]+)@([\\dA-Za-z.-]+)\\.([A-Za-z]{2,6})$', 'keine gültige E-Mail']
        }
    },
    emailValidate: {
        type: 'text',
        label: 'E-Mail bestätigen',
        validation: {
            required: [null, 'dieses Feld kann nicht leer sein'],
            regex: ['^([A-Za-z0-9_.-]+)@([\\dA-Za-z.-]+)\\.([A-Za-z]{2,6})$', 'keine gültige E-Mail']
        }
    },
    phoneNumber: {
        type: 'text',
        label: 'Festnetznummer (Format: 03012345678)',
        validation: {
            required: 'dieses Feld kann nicht leer sein',
            regex: ['^[0-9]*$', 'dieses Feld darf nur Zahlen beinhalten']
        }
    },
    mobileNumber: {
        type: 'text',
        label: 'Handynummer ',
        validation: { regex: ['^[0-9]*$', 'dieses Feld darf nur Zahlen beinhalten'] }
    },
    faxNumber: {
        type: 'text',
        label: 'Faxnummer (Format: 03012345678)',
        validation: { regex: ['^[0-9]*$', 'dieses Feld darf nur Zahlen beinhalten'] }
    },
    birthday: {
        type: 'text',
        label: 'Geburtsdatum',
        validation: {
            required: ['Bitte geben Sie Ihr richtiges Geburtsdatum', 'isBirthdayRequired']
        }
    }
};
var contactDataForm = {
    title: 'Persönliche Daten',
    fields: fieldsDefinition,
    initialValues: {
        idCard: 'Personalausweis',
        lastName: '',
        mailRepetition: true,
        fn_calendarWidget: false,
        offerMode: false,
        showPassword: false,
        firmaRequired: false,
        privatCustomer: true,
        showFax: true,
        showPhoneNumber: true,
        showMobileNumber: true,
        fn_idCard: true
    },
    conditions: {
        isCompany: { fieldName: 'salutation', type: 'equals', value: 'Firma' },
        isFirmaRequirede: { fieldName: 'firmaRequired', type: 'equals', value: true },
        show_calendarWidget: { fieldName: 'fn_calendarWidget', type: 'equals', value: true },
        showOfferButton: { fieldName: 'offerMode', type: 'equals', value: true },
        showMailRepetition: { fieldName: 'mailRepetition', type: 'equals', value: true },
        isShowPassword: { fieldName: 'showPassword', type: 'equals', value: true },
        isBirthdayRequired: { fieldName: 'privatCustomer', type: 'equals', value: true },
        isShowFax: { fieldName: "showFax", type: 'equals', value: true },
        isShowPhoneNumber: { fieldName: "showPhoneNumber", type: 'equals', value: true },
        isShowMobileNumber: { fieldName: 'showMobileNumber', type: 'equals', value: true },
        isShowIdCard: { fieldName: 'fn_idCard', type: 'equals', value: true },
        isShowPersoAusweis: { fieldName: 'idCard', type: 'equals', value: 'Personalausweis' }
    },
    structure: [
        {
            type: 'FormCard',
            props: { title: 'Persönliche Daten' },
            children: [
                {
                    type: 'FormRow',
                    props: { justify: 'around' },
                    children: [
                        {
                            type: 'FormCol',
                            props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                            children: [
                                {
                                    type: 'FormField',
                                    name: 'salutation',
                                    input: 'select'
                                }
                            ]
                        },
                        {
                            type: 'FormCol',
                            props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                            children: [
                                {
                                    type: 'FormField',
                                    name: 'title',
                                    input: 'select',
                                    props: { clearable: true }
                                }
                            ]
                        },
                        {
                            type: 'FormCol',
                            props: { xs: 12, gutter: ['md', 'x'] },
                            children: [
                                {
                                    type: 'FormField',
                                    input: 'text',
                                    name: 'firstName'
                                }
                            ]
                        },
                        {
                            type: 'FormCol',
                            props: { xs: 12, gutter: ['md', 'x'] },
                            children: [
                                {
                                    type: 'FormField',
                                    input: 'text',
                                    name: 'lastName'
                                }
                            ]
                        },
                        // -----------------------------------------------------------------------------------zusätzliche Person--------------------------------------------------------------------
                        // {
                        //   type: 'FormCol',
                        //   props: { xs: 12, gutter: ['md', 'x'] },
                        //   children: [
                        //     {
                        //       type: 'FormField',
                        //       name: 'additionalPerson',
                        //       input: 'text'
                        //     }
                        //   ]
                        // },
                        // -----------------------------------------------------------------------------------zusätzliche Person--------------------------------------------------------------------
                        {
                            type: 'FormCol',
                            props: { xs: 12, gutter: ['md', 'x'] },
                            children: [
                                {
                                    type: 'FormConditionalElement',
                                    props: { conditionName: 'isFirmaRequirede' },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'companyName',
                                            input: 'text'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormCard',
            props: { title: 'Kontakt' },
            children: [
                {
                    type: 'FormRow',
                    props: { justify: 'around', classes: 'tr-flex-wrap' },
                    children: [
                        {
                            type: 'FormCol',
                            props: { xs: 12, gutter: ['md', 'x'] },
                            children: [{ type: 'FormField', input: 'text', name: 'email' }]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'showMailRepetition' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around', classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [{ type: 'FormField', input: 'text', name: 'emailValidate' }]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isShowPhoneNumber' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around', classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [{ type: 'FormField', input: 'text', name: 'phoneNumber' }]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isShowFax' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around', classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [
                                        { type: 'FormField', input: 'text', name: 'faxNumber' }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isShowMobileNumber' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around', classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [{ type: 'FormField', input: 'text', name: 'mobileNumber' }]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormCard',
            props: { title: 'Geburtsdatum' },
            children: [
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'show_calendarWidget' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'birthday',
                                            input: 'text',
                                            props: {
                                                mask: '##.##.####',
                                                // Remove this to extends date picker
                                                extends: 'date'
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'show_calendarWidget', revert: true },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'birthday',
                                            input: 'text',
                                            props: {
                                                mask: '##.##.####'
                                                // Remove this to extends date picker
                                                //  extends: 'date'
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isShowPassword' },
            children: [
                {
                    type: 'FormCard',
                    props: { title: 'Sicherheitskennzahl ' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'sicherheitskennzahl',
                                            input: 'text',
                                            props: {
                                                mask: '####'
                                                // Remove this to extends date picker
                                                //  extends: 'date'
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isShowIdCard' },
            children: [
                {
                    type: 'FormCard',
                    props: { title: 'Identitätsnachweis' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'idCard',
                                            input: 'radio'
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isShowPersoAusweis' },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'persoID',
                                                    input: 'text'
                                                },
                                                {
                                                    type: 'FormField',
                                                    name: 'persoValidTo',
                                                    input: 'text',
                                                    props: {
                                                        mask: '##.##.####',
                                                        // Remove this to extends date picker
                                                        extends: 'date'
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isShowPersoAusweis', revert: true },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'passportID',
                                                    input: 'text'
                                                },
                                                {
                                                    type: 'FormField',
                                                    name: 'passportValidTo',
                                                    input: 'text',
                                                    props: {
                                                        mask: '##.##.####',
                                                        // Remove this to extends date picker
                                                        extends: 'date'
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormField',
                                            name: 'nationality',
                                            input: 'text',
                                            props: { maxlength: 50 }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormDiv',
            props: { class: 'q-pt-md q-px-sm' },
            children: [{ type: 'FormText', props: { text: 'Pflichtfelder sind mit einem Stern * gekennzeichnet.', level: 'body2' } }]
        },
        {
            type: 'FormRow',
            props: { justify: 'between', classes: 'q-py-md' },
            children: [
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [{ type: 'FormBackButton', props: { title: 'zurück', classes: 'full-width' } }]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showOfferButton', revert: false, transition: false },
                            children: [
                                {
                                    type: 'FormClickButton',
                                    props: { title: 'Angebot erstellen' }
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [{ type: 'FormSubmitButton', props: { title: 'Weiter', classes: 'full-width' } }]
                }
            ]
        }
    ]
};
export default contactDataForm;
